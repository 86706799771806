<template>
  <div class="class-statistics">
    <a-breadcrumb class="bread">
      <a-breadcrumb-item>
        <a-icon type="home" />
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link :to="{name: 'NutritionMonitorIndex'}">警员营养监测</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item class="current">{{currentClass.name}}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="date">选择日期：<a-date-picker :allowClear='false' size="large" v-model="selectedDate" @change="onDateChange" :disabled-date="disabledDate" /></div>
    <div class="total">
      <a-row>
        <a-col :span="6">
          <div class="item">
            <div class="tenu">
              <div class="text">总人数</div>
              <div class="num"><b>{{currentClass.statistics?currentClass.statistics.total:0}}</b><span>人</span></div>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="item">
            <div class="tenu">
              <div class="text">超量摄入</div>
              <div class="num"><b class="fprimary">{{currentClass.statistics?currentClass.statistics.nExcess:0}}</b><span>人</span></div>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="item">
            <div class="tenu">
              <div class="text">摄入不足</div>
              <div class="num"><b class="fred">{{currentClass.statistics?currentClass.statistics.nInsufficient:0}}</b><span>人</span></div>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="chart">
            <div
              id="calssdetailchartid"
              style="width: 80px;height:80px;margin:0 auto;"
            ></div>
            <div class="text">健康(<b>{{currentClass.statistics?currentClass.statistics.healthRate:100}}%</b>)</div>
          </div>
        </a-col>
      </a-row>
    </div>
    <student-list></student-list>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import studentList from '@/components/nutritionMonitor/StudentList'
import moment from 'moment'
import apis from '@/business/apis'
export default {
  inject: ['eventBus'],
  components: {
    studentList
  },
  data () {
    return {
      selectedDate: null
    }
  },
  computed: {
    ...mapState('nutrition', [
      'currentClass'
    ]),
    ...mapState('school', [
      'currentSchool'
    ])
  },
  methods: {
    ...mapActions('nutrition', [
      'saveCurrClass'
    ]),
    initChart () {
      const unHealth = this.currentClass.statistics ? this.currentClass.statistics.nExcess + this.currentClass.statistics.nInsufficient : 0
      const health = this.currentClass.statistics ? this.currentClass.statistics.total - unHealth : 0
      const chart = this.$echarts.init(document.getElementById('calssdetailchartid'))
      const colorList = ['#4bdd4d', '#fc0d1b']
      const option = {
        tooltip: {
          trigger: 'item',
          formatter:
            "<b style='font-size:18px;color:#ffce0a;font-family:DIN Alternate;'>{c}</b><span style='color:#ffce0a;'>人</span>({d}%)"
        },
        series: [
          {
            type: 'pie',
            radius: ['85%', '100%'],
            avoidLabelOverlap: false,
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex]
                }
              }
            },
            data: [{ value: health }, { value: unHealth }]
          }
        ]
      }
      chart.setOption(option)
    },
    async onDateChange (data) {
      this.eventBus.$emit('nutrition-class-date-change', data)
      const query = '?schoolId=' + this.currentSchool._id + '&date=' + this.selectedDate.unix() + '&groupId=' + this.currentClass._id
      const respose = await this.$http.get(apis.nutrition.oneClassStatistics + query)
      const temp = {
        date: this.selectedDate,
        name: this.currentClass.name,
        _id: this.currentClass._id,
        statistics: respose.body
      }
      temp.statistics.excessRate = 0
      temp.statistics.insufficientRate = 0
      if (temp.statistics.total > 0) {
        temp.statistics.excessRate = Math.round(temp.statistics.nExcess / temp.statistics.total * 100)
        temp.statistics.insufficientRate = Math.round(temp.statistics.nInsufficient / temp.statistics.total * 100)
      }
      temp.statistics.healthRate = 100 - temp.statistics.excessRate - temp.statistics.insufficientRate
      await this.saveCurrClass(temp)
      this.initChart()
    },
    disabledDate (date) {
      const now = moment()
      if (date <= now && date > now.subtract(15, 'days')) return false
      return true
    }
  },
  mounted () {
    this.selectedDate = this.currentClass.date ? moment(this.currentClass.date) : moment()
    this.initChart()
  }
}
</script>

<style lang="less">
.class-statistics {
  .bread {
    margin-top:-4px;
    margin-bottom: 20px;
    .current{
      font-size: 20px;
    }
  }
  .date {
    margin-bottom: 24px;
  }
  .total {
    border: 1px solid #e7e7eb;
    background: #ffffff;
    border-radius: 6px;
    padding: 16px 0;
    .item {
      line-height: 1;
      height: 80px;
      padding: 0 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 16px;
        right: 0;
        bottom: 16px;
        background: #e7e7eb;
        width: 1px;
      }
      .tenu {
        .num {
          margin-top: 10px;
          b {
            font-size: 30px;
          }
          span {
            font-size: 12px;
            font-weight: normal;
            margin-left: 4px;
          }
        }
      }
    }
    .chart {
      position: relative;
      .text {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -8px;
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .fili {
    margin-top: 24px;
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    padding: 0 16px;
    > .title {
      padding-top: 16px;
      display: flex;
      align-items: center;
      h1 {
        flex: 1;
        font-size: 20px;
      }
      .fun {
        display: flex;
        align-items: center;
        .search {
          .ant-input {
            height: 44px;
          }
          .anticon {
            font-size: 18px;
          }
        }
        .button {
          display: flex;
          align-items: center;
          .ant-btn {
            width: 80px;
            margin-left: 16px;
          }
        }
      }
    }
    .list {
      .table {
        margin: 16px 0;
        .avna {
          .avatar {
            margin-right: 2px;
          }
        }
        .sp {
          margin: 0 2px;
        }
      }
    }
  }
}
</style>
