<template>
   <div class="fili">
      <div class="title">
        <h1>人员列表</h1>
        <div class="fun">
          <search placeholder="请输入姓名、编号" @searchKeyChange="loadData" ref="search"></search>
          <div class="button">
            <a-button type="primary" size="large" @click="exportExcel" :loading="isDownloading" :disabled="!data||!data.length">导出</a-button>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="table">
          <a-table
            :columns="columns"
            :data-source="data"
            bordered
            class="custom-table"
            :pagination="pagination"
            @change="tableChange"
            rowKey="_id"
          >
            <template
              slot="name"
              slot-scope="text, record"
            >
              <div class="avna">
                <a-avatar
                  :src="avatarUrl"
                  :size="24"
                  class="avatar"
                />
                {{record.name}}
              </div>
            </template>
            <template
              slot="heatkc"
              slot-scope="text, record"
            >
              <b class="fgreen">{{record.statistics.heatkc}}</b><span class="sp">/{{record.statistics.suggest_heatkc}}</span>千卡
            </template>
            <template
              slot="carbohydr"
              slot-scope="text, record"
            >
              {{record.statistics.carbohydr}}g
            </template>
            <template
              slot="protein"
              slot-scope="text, record"
            >
              {{record.statistics.protein}}g
            </template>
            <template
              slot="fat"
              slot-scope="text, record"
            >
              {{record.statistics.fat}}g
            </template>
            <template slot="operation" slot-scope="text, record">
              <router-link :to="{name: 'NutritionReport',params:{userId:record._id}}">营养报告</router-link>
            </template>
          </a-table>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import downloadjs from 'downloadjs'
import search from '@/components/common/Search'
import apis from '@/business/apis'
import avatarUrl from '@/assets/images/default-avatar.jpg'
const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '性别',
    dataIndex: 'gender',
    width: 70
  },
  {
    title: '编号',
    dataIndex: 'num',
    width: 140
  },
  {
    title: '今日摄入',
    dataIndex: 'heatkc',
    scopedSlots: { customRender: 'heatkc' }
  },
  {
    title: '碳水化合物',
    dataIndex: 'carbohydr',
    scopedSlots: { customRender: 'carbohydr' }
  },
  {
    title: '蛋白质',
    dataIndex: 'protein',
    scopedSlots: { customRender: 'protein' }
  },
  {
    title: '脂肪',
    dataIndex: 'fat',
    scopedSlots: { customRender: 'fat' }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: 90
  }
]
export default {
  inject: ['eventBus'],
  components: {
    search
  },
  data () {
    return {
      avatarUrl,
      isDownloading: false,
      data: [],
      columns,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 50
      },
      selectedDate: null
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ]),
    ...mapState('nutrition', [
      'currentClass'
    ])
  },
  methods: {
    async loadData () {
      let query = '?schoolId=' + this.currentSchool._id + '&current=' + this.pagination.current + '&groupId=' + this.currentClass._id + '&date=' + this.selectedDate.unix()
      if (this.$refs.search && this.$refs.search.searchKey) {
        query += ('&keyword=' + this.$refs.search.searchKey)
      }
      const response = await this.$http.get(apis.nutrition.studentsNutrition + query)
      this.data = response.body.students
      this.pagination = response.body.pagination
      // console.log(response.body)
      this.data.forEach(item => {
        item.statistics = {}
        if (response.body.statistics[item._id]) {
          item.statistics = response.body.statistics[item._id]
        }
      })
    },
    tableChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.loadData()
    },
    async exportExcel () {
      this.$message.info('正在导出...')
      this.isDownloading = true
      setTimeout(() => {
        this.isDownloading = false
      }, 1000)
      const data = { schoolId: this.currentSchool._id, groupId: this.currentClass._id, date: this.selectedDate.unix() }
      if (this.$refs.search && this.$refs.search.searchKey) {
        data.keyword = this.$refs.search.searchKey
      }
      const response = await this.$http.post(apis.nutrition.studentsNutritionExport, data, { responseType: 'blob' })
      if (!response || !response.ok) {
        this.$message.info('导出失败')
        return
      }
      downloadjs(
        await response.blob(),
        `${this.currentClass.name}_${this.selectedDate.format('YYYY-MM-DD')}.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    },
    async dateChangeHandle (data) {
      this.selectedDate = data
      await this.loadData()
    }
  },
  async created () {
    this.eventBus.$on('nutrition-class-date-change', this.dateChangeHandle)
    this.selectedDate = this.currentClass.date ? moment(this.currentClass.date) : moment()
    await this.loadData()
  },
  beforeDestroy () {
    this.eventBus.$off('nutrition-class-date-change', this.dateChangeHandle)
  }
}
</script>
